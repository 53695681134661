
    import Cookies from 'js-cookie';

    const trackObject = {
        category: 'Privacy',
        label: 'Cookie Notice',
        value: 1,
    };

    export default {
        inject: {
            '$webAnalytics': {
                default: {
                    track () {
                        console.warn('CookieGate: $webAnalytics not provided by app - cant inject');
                    },
                },
            },
        },

        props: {
            bodyCopy: {
                required: true,
                type: String,
            },

            buttonCopy: {
                required: true,
                type: String,
            },

            /**
             * The type of button used to close the message
             * @values button, null
             */
            buttonType: {
                required: false,
                type: String,
                default: 'button',
            },

            titleCopy: {
                required: true,
                type: String,
            },

            /**
             * The display type of the cookie gate message
             * @values toast, popup
             */
            type: {
                required: false,
                type: String,
                default: 'toast',
            },
        },

        mounted () {
            if (!this.viewedCookieDisclaimer) this.$webAnalytics.track('Display', trackObject);
        },

        data () {
            return {
                closed: false,
                viewedCookieDisclaimer: Cookies.get('viewedCookieDisclaimer'),
            };
        },

        methods: {
            close () {
                this.closed = true;
                Cookies.set('viewedCookieDisclaimer', true);
                this.$webAnalytics.track('Accept', trackObject);
            },
        },
    };
